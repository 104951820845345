const menu = document.querySelector('.menu')
const menuBackdrop = document.querySelector('.menu-backdrop')
const menuItems = document.querySelectorAll('.menuItem')
const hamburger = document.querySelector('.hamburger')
const closeIcon = document.querySelector('.closeIcon')
const menuIcon = document.querySelector('.menuIcon')

function toggleMenu() {
  if (menu.classList.contains('showMenu')) {
    menu.classList.remove('showMenu')
    menuBackdrop.classList.remove('showMenu')
    closeIcon.style.display = 'none'
    menuIcon.style.display = 'block'
  } else {
    menu.classList.add('showMenu')
    menuBackdrop.classList.add('showMenu')
    closeIcon.style.display = 'block'
    menuIcon.style.display = 'none'
  }
}

hamburger.addEventListener('click', toggleMenu)
menuBackdrop.addEventListener('click', toggleMenu)

menu.querySelectorAll('.menuItem').forEach((item) => {
  item.addEventListener('click', toggleMenu)
})
