// import Swiper JS
import Swiper, { Navigation } from 'swiper'

Swiper.use([Navigation])

new Swiper('.c-fact-carousel', {
  loop: true,

  // Navigation arrows
  navigation: {
    nextEl: '#fact-carousel-button-next',
    prevEl: '#fact-carousel-button-prev',
  },
})
