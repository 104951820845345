// import Swiper JS
import Swiper, { Navigation } from 'swiper'

Swiper.use([Navigation])

new Swiper('.c-present__carousel', {
  loop: false,

  slidesPerView: 1.2,
  spaceBetween: 10,
  // Responsive breakpoints
  breakpoints: {
    1024: {
      slidesPerView: 2.4,
      spaceBetween: 40,
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '#c-present__carousel-button-next',
    prevEl: '#c-present__carousel-button-prev',
  },
})
